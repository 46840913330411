<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >
          <el-form-item label="所属企业:" prop="compId">
            <el-select
              v-model="searchModel.compId"
              placeholder="请选择所属企业"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="item in compcompList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆类型:" prop="vehiType">
            <DataSelect v-model="searchModel.vehiType" dictCode="vehi_type_options"></DataSelect>
          </el-form-item>
          <el-form-item label="车辆归属类型:" prop="ownType">
            <el-select
              v-model="searchModel.ownType"
              placeholder="请选择车辆归属类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option label="自备车" value="1"> </el-option>
              <el-option label="外租车" value="2"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号:" prop="busLicPlate">
            <el-input v-model="searchModel.busLicPlate"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-close"
              v-if="loading"
              @click="loading = false"
            >停止</el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="busInfo2"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :align="allAlign"
          :data="tableData"
        >
          <template #buttons>
            <el-button
              type="primary"
              v-auth="'信息管理_设备档案_外部车辆_新增'"
              icon="el-icon-circle-plus-outline"
              @click="
                dialogEdit = true;
                addRow();
              "
            >新增车辆
            </el-button>
            <el-upload
              style="display:inline-block"
              action="#"
              class="upload"
              :auto-upload="false" accept=".xlsx,.xls" :on-change="handleExcel" :show-file-list="false">
              <el-button
                class="ml-10 mr-10"
                v-auth="'信息管理_设备档案_外部车辆__导入'"
                type="default"
                icon="el-icon-download"
              >导入
              </el-button></el-upload>
            <el-button v-auth="'信息管理_设备档案_外部车辆_模板下载'" size="small" type="success" icon="el-icon-tickets" @click="downloadTem();">模板下载</el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
          <vxe-table-column
            field="compId"
            title="所属企业"
            show-overflow="title"
            min-width="80"
            show-header-overflow="title"
            :formatter="
              ({ cellValue, row, column }) => {
                return cellValue ? compcompListOptions[cellValue] : '';
              }
            "
          />
          <vxe-table-column
            field="compId0"
            title="使用企业"
            show-overflow="title"
            min-width="80"
            show-header-overflow="title"
            :formatter="
              ({ cellValue, row, column }) => {
                return cellValue ? compListOptions[cellValue] : '';
              }
            "
          />
          <vxe-table-column
            field="ssgys"
            title="所属供应商"
            show-overflow="title"
            min-width="80"
            show-header-overflow="title"
            :formatter="
              ({ cellValue, row, column }) => {
                return row.compId ? compcompListOptions[row.compId] : '';
              }
            "
          />
          <vxe-table-column
            field="busLicPlate"
            title="车牌号"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <!--  <vxe-table-column field="compId" title="企业编号" show-overflow="title" min-width="60" show-header-overflow="title"/>-->
          <vxe-table-column
            field="ownerCode"
            title="自编号"
            show-overflow="title"
            min-width="80"
            show-header-overflow="title"
          />
          <!--      <vxe-table-column field="lwhTank" title="罐厢尺寸(长*宽*高)" show-overflow="title" min-width="120" show-header-overflow="title"/>
              <vxe-table-column field="lwhOut" title="外形尺寸(长*宽*高)" show-overflow="title" min-width="120" show-header-overflow="title"/>-->
          <vxe-table-column
            field="ownType"
            title="车辆归属类型"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
            :formatter="
              ({ cellValue, row, column }) => {
                return cellValue ? ownTypeOptions[cellValue] : '';
              }
            "
          />
          <vxe-table-column
            field="vehiTypeName"
            title="车辆类型"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="netWeight"
            title="整备质量(吨)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="weightRated"
            title="额定质量(吨)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="totalWeight"
            title="总质量(吨)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="ratedCubage"
            title="额定方量(方)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="totalCubage"
            title="总方量(方)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <!--                <vxe-table-column field="status" title="状态" min-width="60" show-overflow="title" show-header-overflow="title":formatter="
                      ({cellValue, row, column }) => {
                        return cellValue==1?'在线':'离线';
                      }"/>-->
          <vxe-table-column title="操作" min-width="150">
            <template v-slot="{ row }">
              <el-button type="primary" @click="handlerQrcode(row)" title="二维码">二维码</el-button>
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)" v-auth="'信息管理_设备档案_外部车辆_修改'" title="修改"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
      <el-dialog
        :title="dialogEditTitle"
        :visible.sync="dialogEdit"
        :v-if="dialogEdit"
        :append-to-body="true"
        width="850px"
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :inline="true"
          ref="addForm"
          size="mini"
          label-position="right"
          label-width="160px"
          :model="busEditModel"
          :rules="rules"
          style="margin-top: 20px"
        >
          <el-form-item label="所属企业:" prop="compId">
            <el-select
              v-model="busEditModel.compId"
              placeholder="请选择所属企业"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value, key) in compcompListOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用企业:" prop="compId0">
            <el-select
              v-model="busEditModel.compId0"
              placeholder="请选择使用企业"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value, key) in compListOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商:" prop="compId">
            <el-select
              v-model="busEditModel.compId"
              placeholder="请选择所属企业"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(value, key) in compcompListOptions"
                :key="key"
                :label="value"
                :value="key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号:" props="busLicPlate">
            <el-input v-model="busEditModel.busLicPlate" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="车辆类型:">
            <DataSelect v-model="busEditModel.vehiType" dictCode="vehi_type_options"></DataSelect>
          </el-form-item>
          <el-form-item label="外形尺寸(长*宽*高 /m):" props="lwhOut">
            <el-input v-model="busEditModel.lwhOut" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="罐厢尺寸(长*宽*高 /m):">
            <el-input v-model="busEditModel.lwhTank" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="车辆归属类型:" props="ownType">
            <el-select
              v-model="busEditModel.ownType"
              placeholder="请选择车辆归属类型"
              filterable
              clearable
              style="width: 200px"
            >
              <el-option label="自备车" value="1"> </el-option>
              <el-option label="外租车" value="2"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="自编号:">
            <el-input v-model="busEditModel.ownerCode" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="额定方量(方):" props="ratedCubage">
            <el-input v-model="busEditModel.ratedCubage" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="总方量(方):">
            <el-input v-model="busEditModel.totalCubage" style="width: 200px">
            </el-input>
          </el-form-item>
          <el-form-item label="额定质量(吨):" props="weightRated">
            <el-input v-model="busEditModel.weightRated" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="总质量(吨):">
            <el-input v-model="busEditModel.totalWeight" style="width: 200px">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitBusEdit()" size="mini"
          >保存</el-button
          >
        </div>
      </el-dialog>
      <qr-code :visible.sync="codeVisible" :content="qrCodeContent" />
    </div>
  </PageContainer>
</template>

<script>
import DataSelect from '../../components/Dictionary/DataSelect.vue'
import { getComps } from '@/apis/common'
import { gianSqComplist } from '@/apis/infoManage/compBaseInfo'
import QrCode from '@/components/QrCode'
import {
  gianBusInfolist2,
  importExcelVehi,
  setBusInfo,
  downTemVehi
} from '@/apis/infoManage/busInfo'
import XEUtils from 'xe-utils'
import { exportToxlsx } from '@/utils/tools'
export default {
  components: {
    QrCode,
    DataSelect
  },
  name: 'bus-info',
  data () {
    return {
      compcompList: [],
      compcompListOptions: {},
      busEditModel: {
        compId0: '',
        busLicPlate: '',
        lwhOut: '',
        lwhTank: '',
        netWeight: 0,
        ownType: '',
        ownerCode: '',
        ratedCubage: 0,
        totalCubage: 0,
        totalWeight: 0,
        vehiId: '',
        vehiType: '',
        weightRated: 0,
        compId: ''
      },
      rules: {
        compId: [
          { required: true, message: '企业名称为必填选项', trigger: 'change' }
        ]
      },
      vehiTypeOptions: {
        1: '泵车',
        2: '混凝土搅拌车',
        3: '散装水泥车',
        4: '预拌砂浆车'
      },
      dialogEditTitle: '新增车辆',
      dialogEdit: false,
      searchModel: {
        busLicPlate: '',
        ownType: '',
        vehiType: '',
        compId: ''
      },
      ownTypeOptions: {
        1: '自备车',
        2: '外租车'
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      },
      companyList: [],
      compListOptions: {},
      qrCodeContent: '',
      codeVisible: false
    }
  },
  methods: {
    downloadTem () {
      downTemVehi().then(res => {
        exportToxlsx(res, '模板.xlsx')
      })
    },
    handleExcel (file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('num', 1)
      importExcelVehi(formData).then(res => {
        if (res.code == '200') {
          this.$message.success('文件导入成功！')
          this.tablePage.pageNum = 1
          this.initList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlerQrcode (row) {
      this.codeVisible = true
      this.qrCodeContent = `车牌号: ${row.busLicPlate}`
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      this.loading = true
      gianBusInfolist2({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    addRow () {
      this.dialogEditTitle = '新增车辆'
      this.busEditModel = {
        compId0: '',
        busLicPlate: '',
        lwhOut: '',
        lwhTank: '',
        netWeight: 0,
        ownType: '',
        ownerCode: '',
        ratedCubage: 0,
        totalCubage: 0,
        totalWeight: 0,
        vehiId: '',
        vehiType: '',
        weightRated: 0,
        compId: ''
      }
    },
    editRow (row) {
      //  this.modifyDetailRow = row
      this.dialogEditTitle = '修改车辆'
      const rowData = {}
      XEUtils.objectEach(this.busEditModel, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
      })
      this.busEditModel = Object.assign({}, rowData)
      this.dialogEdit = true
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    onSubmitBusEdit () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          setBusInfo({ ...this.busEditModel }).then((res) => {
            if (res.code === 200) {
              if (this.busEditModel.vehiId != '') {
                this.$message.success('修改成功！')
              } else {
                this.$message.success('添加成功！')
              }
              this.dialogEdit = false
              this.refreshData()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    handleItemChange (val) {}
  },
  mounted () {
    gianSqComplist({
      pageNum: 1,
      pageSize: 9999
    }).then(res => {
      if (res.code == 200) {
        this.compcompList = res.data.rows
        const temp = {}
        this.compcompList.forEach((item) => {
          temp[item.compId] = item.compName
        })
        this.compcompListOptions = temp
      }
    })
    getComps({})
      .then((res) => {
        if (res.code == 200) {
          this.companyList = res.data.rows
          const temp = {}
          this.companyList.forEach((item) => {
            temp[item.compId] = item.compName
          })
          this.compListOptions = temp
        }
        this.refreshData()
      })
      .catch((res) => {
        console.log(res)
      })
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: white;
}
</style>
